@use "src/styles/palette";
@use "src/styles/layout";
@use "src/styles/mixins";

.pill {
  padding: 0.75rem 1.25rem;
  border-radius: 2rem;
  background-color: var(--warning-yellow);
  border: 2px solid var(--warning-yellow);
  color: black;
  transition:
    background 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
  &:hover {
    background-color: var(--warning-yellow-hover);
    border-color: var(--warning-yellow-hover);
  }
  @media (max-width: layout.breakpoint(md)) {
    padding: 0.5rem 1rem;
  }

  &.active {
    background-color: var(--secondary-base);
    border-color: var(--secondary-base);
    color: white;
    &:hover {
      background-color: var(--secondary-base-hover);
      border-color: var(--secondary-base-hover);
    }
    &:has(.cross) {
      padding-right: 0.6rem;
    }
  }

  &.variant_light {
    background-color: var(--warning-yellow-light);
    border-color: var(--warning-yellow);
    &:hover {
      background-color: var(--warning-yellow-hover);
      border-color: var(--warning-yellow-hover);
    }
  }

  &.variant_transparent {
    background-color: transparent;
    border-color: var(--grey-border);
    &.active {
      background-color: black;
      border-color: black;
      color: white;
    }
    &:hover {
      border-color: black;
    }
  }

  &.variant_dark {
    background-color: black;
    color: white;
    border-color: black;
  }

  &.small {
    padding: 0.25rem 1rem;
  }
}

.variant_750 {
  background-color: var(--warning-yellow-light);
  border-color: var(--warning-yellow);
  &:hover {
    background-color: var(--warning-yellow-hover);
    border-color: var(--warning-yellow-hover);
  }
}

.icon {
  width: 1.375rem;
  height: 1.375rem;
  flex-shrink: 0;
}

.cross {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

[data-theme="750"] {
  .pill:not(.disable_theming) {
    --750-gradient: linear-gradient(
      var(--warning-yellow),
      var(--warning-yellow)
    );

    &:global(.color-1) {
      --750-gradient: var(--750-gradient-01-extended);
    }
    &:global(.color-2) {
      --750-gradient: var(--750-gradient-02-extended);
    }
    &:global(.color-3) {
      --750-gradient: var(--750-gradient-03-extended);
    }
    &:global(.color-4) {
      --750-gradient: var(--750-gradient-04-extended);
    }

    color: black;
    border: 2px solid transparent;
    background:
      linear-gradient(white, white) padding-box,
      var(--750-gradient) border-box;
    background-size: 200% 100%;
    position: relative;
    transition: background-position 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 2rem;
      background: var(--750-gradient);
      background-size: 200% 100%;
      z-index: -1;
      opacity: 0;
      transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
    &:hover {
      color: white;
      background: transparent;

      &:global(.color-4) {
        color: #000;
      }

      &:before {
        opacity: 1;
      }
    }
    &.active {
      color: white;
      background:
        var(--750-gradient) padding-box,
        var(--750-gradient) border-box;
      background-size: 200% 100%;
      &:hover {
        background-position: 100%;
      }
      &:has(.cross) {
        padding-right: 0.6rem;
      }
    }
  }
}
