@use "src/styles/buttons";
@use "src/styles/layout";
@use "src/styles/palette";
@use "src/styles/typography";

$arrowSize: 3.125rem;

.carousel_wrapper {
  position: relative;
  margin-bottom: 0;
  transition: margin-bottom 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.carousel {
  overflow: auto;
  display: flex;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: hidden;
  border-radius: 0.25rem;

  @media (max-width: layout.breakpoint(md)) {
    border-radius: 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.slide {
  width: 100%;
  aspect-ratio: 906 / 513;
  flex-shrink: 0;
  scroll-snap-align: start;
  > div:first-child {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.controls {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  top: 50%;

  @media (max-width: layout.breakpoint(md)) {
    display: none;
  }
}

.button {
  @extend %reset-button;
  position: absolute;
  background: palette.grey(light);
  color: black;
  width: $arrowSize;
  height: $arrowSize;
  border-radius: $arrowSize / 2;
  border: 2px solid palette.grey(border);
  transition:
    transform 300ms cubic-bezier(0.4, 0, 0.2, 1),
    border-color 300ms cubic-bezier(0.4, 0, 0.2, 1);

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:hover {
    background-color: palette.grey(light);
    border-color: black;
  }

  @media (max-width: layout.breakpoint(md)) {
    display: none;
  }

  &_left {
    @extend .button;
    left: 1rem;
  }

  &_right {
    @extend .button;
    right: 1rem;
  }
}

.counter {
  position: absolute;
  bottom: 2.5rem;
  left: 1rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0.25rem 0.625rem;
  border-radius: 2rem;
}
