@use "src/styles/layout";
@use "src/styles/palette";
@use "src/styles/typography";

.hero {
  position: relative;
  background-color: palette.brand("black");
  color: palette.$white;
  padding-bottom: 5rem;
  margin-bottom: -5rem;
}

.desktop_banner {
  max-width: 300px;

  @media (min-width: layout.breakpoint(md)) {
    max-width: 680px;
  }
}

.container {
  @include layout.container();

  padding: 4rem 0 3.5rem 0;
  width: calc(100% - 2rem);
}

.tag {
  padding: 0.25rem 0.625rem;
  border-radius: 0.25rem;
  display: inline-block;
  font-weight: 700;
  font-size: 0.875rem;
}

.category_tag {
  @extend .tag;
  background-color: palette.brand(primary);
}

.header_tag {
  @extend .tag;
  margin-top: 1rem;
  background-color: palette.$matte-black;
}

.title {
  @extend .header_1;

  width: 90%;
  margin: 1.5rem 0 0;
  word-break: break-word;
}

.button_bar {
  margin-top: 1rem;
  padding: 0 0.4rem;

  @media (max-width: layout.breakpoint("md")) {
    padding: 0 0.2rem;
  }
}

[data-theme="750"] {
  .hero {
    background: var(--750-gradient-05);

    .container {
      padding: 7.5rem 0 3.5rem 0;

      @media (min-width: layout.breakpoint(sm)) {
        padding: 11rem 0 3.5rem 0;
      }

      @media (min-width: layout.breakpoint(md)) {
        padding: 3.5rem 0;
      }
    }

    .title {
      padding-right: 2rem;
      color: #000;
      text-transform: uppercase;
    }

    .button_bar {
      color: #000;
    }

    .category_tag {
      @extend .tag;
      background-color: #fff;
      color: #000;
    }
  }
}
