@use "src/styles/layout";
@use "src/styles/typography";

$imageAnimationDuration: 0.5s;

.container {
  --support-color: #f0f0f0;
  --background-color: #000000;

  @include layout.container(xl);
  height: calc(100% - 9.625rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;

  [data-animation-id="slideIn"] {
    opacity: 0;
  }
}

.question_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 2.5rem;
  min-height: 680px;

  @media (max-width: layout.breakpoint(md)) {
    grid-template-columns: 1fr;
    min-height: auto;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  padding: 0 1rem;

  @media (max-width: layout.breakpoint(md)) {
    text-align: center;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
}

.text_wrapper {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  color: var(--support-color);

  @media (max-width: layout.breakpoint(md)) {
    gap: 1rem;
  }
}

.overline {
  @extend %quiz_overline;
}

.title {
  @extend %quiz_header_2;
}

.answer_buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.answer_button {
  transition:
    background-color 0.3s,
    color 0.3s;
  border-color: var(--support-color) !important;
  color: var(--support-color) !important;

  @media (max-width: layout.breakpoint(md)) {
    text-align: left;
  }

  &:hover,
  &:focus-visible,
  &.is_selected_answer {
    background-color: var(--support-color) !important;
    color: var(--background-color) !important;
  }

  &:focus-visible {
    transform: rotate(2deg) !important;
  }
}

.images_wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  > * {
    grid-area: 1/1;
  }

  @media (max-width: layout.breakpoint(md)) {
    position: absolute;
    opacity: 0.5;
  }

  .image {
    position: absolute;
    inset: 0;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    transition:
      transform $imageAnimationDuration,
      opacity $imageAnimationDuration,
      display $imageAnimationDuration;
    transition-behavior: allow-discrete;
    display: none;
    opacity: 0;
    transform: rotate(-5deg) translateX(5%);

    &.show {
      display: block;
      opacity: 1;
      transform: rotate(0deg) translateX(0);

      @starting-style {
        opacity: 0;
        transform: rotate(5deg) translateX(5%);
      }
    }
  }
}
