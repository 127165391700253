@use "src/styles/typography";
@use "src/styles/layout";
@use "src/styles/buttons";
@use "src/styles/palette";

.overview {
  margin-top: 5rem;

  @media (max-width: layout.breakpoint(md)) {
    margin-top: 2.5rem;
  }
}

.month_buttons {
  display: flex;
  gap: 1rem;
  padding: 1rem 0;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  .month_button {
    @extend %reset-button;

    font-weight: normal;
    padding: 0.5rem 0;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: transparent;
    text-transform: capitalize;

    &__active {
      font-weight: bold;
      border-bottom-color: inherit;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
}



.dates {
  border-collapse: collapse;
  width: 100%;

  tr {
    border-bottom: 1px solid palette.grey(border);
    &:last-child {
      border-bottom: none;
    }
    td {
      padding: 1rem 2rem 1rem 0;
      
    }
  }

  &_date {
    text-transform: capitalize;
    width: 1px;
    white-space: nowrap;
  }
}

.title {
  @extend .header_4;
}