@use "src/styles/layout";
@use "src/styles/typography";
@use "src/styles/palette";

.container {
  @include layout.container(xl);
  .title {
    color: var(--support-color);
    font-size: 2.5rem;
    line-height: 95%;
  }

  .buttons {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    > button,
    a {
      color: var(--support-color);
    }

    .add_events_to_favourites_button {
      background-color: var(--white);
      transition: transform 0.3s;

      &:hover {
        background-color: palette.$white;
        transform: scale(1.05);
      }
    }

    .next_section_button {
      font-weight: normal;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}