@use "src/styles/layout";
@use "src/styles/typography";
@use "src/styles/palette";

.container {
  position: relative;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 
    "title buttons"
    "grid grid";
  justify-content: center;
  align-items: center;
  row-gap: 1.5rem;

  @media (max-width: layout.breakpoint(md)) {
    grid-template-columns: 100%;
    grid-template-areas: 
      "title"
      "grid"
      "buttons";
  }
}

.title {
  grid-area: title;
  @extend %quiz_header_4;
  color: var(--support-color);
}

.buttons {
  grid-area: buttons;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;

  > button,
  a {
    color: var(--support-color);
  }

  .cta_button {
    background-color: var(--white);
    transition: transform 0.3s;

    &:hover {
      background-color: palette.$white;
      transform: scale(1.1);
    }
  }

  @media (max-width: layout.breakpoint(md)) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.grid {
  grid-area: grid;
  width: 100%;
}