@use "src/styles/palette";
@use "src/styles/layout";
@use "src/styles/layers";

.ErrorMessage {
  display: flex;
  width: 100%;
  gap: 1rem;
  color: palette.error('dark');
  background-color: palette.error('light');
  padding: 1.5rem;
  border-radius: var(--border-radius);

  &_black {
    color: palette.$white;
    background-color: palette.brand('black', 'base');
  }

  &_centered {
    justify-content: center;
  }
}

.toast_wrapper {
  @include layout.container();
  z-index: layers.$toast;
  position: relative;
  width: 80vw;

  @media (max-width: layout.breakpoint('md')) {
    width: calc(100vw - 2rem);
    left: 0;
  }
}