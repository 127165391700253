$transition: 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

.checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem;
  cursor: pointer;
}

.checkbox_input {
  width: 0;
  opacity: 0;
}

.checkbox_box {
  min-width: 1.5rem;
  min-height: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.25rem;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  color: transparent;
  background-color: #ffffff;

  transition:
    color $transition,
    background-color $transition;

  &__checked {
    @extend .checkbox_box;

    background-color: #000000;
    color: #ffffff;
  }
}

.checkbox_input:focus + .checkbox_box {
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
  outline: none;
}

.checkbox_label {
  margin-left: 0.5rem;
}
