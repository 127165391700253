@use "src/styles/palette";
@use "src/styles/buttons";
@use "src/styles/layout";
@use "src/styles/animations";
@use "src/styles/typography";

.partners {
  margin: 3.75rem 0;

  .intro {
    span {
      @extend .header_3;
    }
    .description {
      @extend .body__bold;
      color: palette.$matte-black;
    }

    margin-bottom: 2.25rem;
  }

  .title {
    @extend .header_5;
    margin-bottom: 1rem;
  }
}

.main {
  margin-bottom: 2.5rem;

  .image {
    width: 6.875rem;
    height: 6.875rem;
    margin-bottom: 2.25rem;
    border: 1px solid palette.grey(border);
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    transition: transform 0.3s ease-in-out;
    padding: 0.125rem;

    img {
      height: 100%;
    }

    @media (min-width: layout.breakpoint("sm")) {
      width: 5.125rem;
      height: 5.125rem;
    }

    &:hover {
      transform: scale(1.05);
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  @media (min-width: layout.breakpoint("sm")) {
    flex-direction: row;
  }

  .column {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
  }

  .column:last-child {
    @media (min-width: layout.breakpoint(lg)) {
      width: 33%;
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    .image {
      width: 6.25rem;
      height: 6.25rem;
      border: 1px solid palette.grey(border);
      border-radius: 0.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform 0.3s ease-in-out;
      padding: 0.125rem;

      img {
        object-fit: contain;
        height: 100%;
      }

      @media (min-width: layout.breakpoint("sm")) {
        width: 4.6875rem;
        height: 4.6875rem;
      }

      &:hover {
        transform: scale(1.05);
      }
    }
  }
}

[data-theme="750"] {
  .intro {
    .title {
      background: var(--750-gradient-02);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
