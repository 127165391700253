.container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.iframe {
  border: none;

  &__noScroll {
    overflow: hidden;
  }
}