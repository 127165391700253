@use "src/styles/layout";

.hero_desktop,
.hero_mobile {
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}

.hero_mobile {
  height: 80px;

  @media (min-width: 420px) {
    height: 100px;
  }

  @media (min-width: layout.breakpoint(sm)) {
    height: 160px;
  }

  @media (min-width: layout.breakpoint(md)) {
    display: none;
  }
}

.hero_desktop {
  display: none;

  @media (min-width: layout.breakpoint(md)) {
    display: block;
  }
}
