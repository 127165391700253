@use "src/styles/buttons";

.show_filters_button {
  @extend %reset-button;
  position: relative;
}

.filter_counterbadge {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  background-color: white;
  color: black;
  width: 1.5rem;
  height: 1.5rem;
}

.bar {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
