@use "src/styles/palette";

.avatar {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 4.5rem;
  overflow: hidden;
  position: relative;

  > img {
    width: 100%;
    height: 100%;
  }

  &_fallback {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    background-color: palette.grey();
    color: white;
    height: 100%;
    width: 100%;
  }
}
