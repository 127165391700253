@use "src/styles/layout";

.grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;

  @media (max-width: layout.breakpoint(md)) {
    grid-template-columns: 1fr;
  }
}

.slider {
  @media (max-width: layout.breakpoint(md)) {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    padding-left: 4rem;
    gap: 1rem;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.card {
  @media (max-width: layout.breakpoint(md)) {
    min-width: 80%;
    scroll-snap-align: start;

    &:last-child {
      margin-right: 4rem;
    }
  }
}