.wrapper {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  gap: 0.625rem;
}

.item_row {
  width: 100%;
}

.link {
  &:hover {
    text-decoration: none;
  }
  &:hover > span {
    background-size: 0 !important;
  }
}
