@use "src/styles/layout";
@use "src/styles/typography";
@use "src/styles/palette";

$imageAnimationDuration: 0.5s;

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10rem;

  > .section {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 2.5rem 0 6rem;
    width: calc(100% - 8.5rem);

    &__full {
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }
  }

  @media (max-width: layout.breakpoint(md)) {
    margin-bottom: 5rem;
    > .section {
      margin: 0 1.5rem;
      width: calc(100% - 3rem);
    }
  }
}

.next_section_button {
  transition: transform 0.3s;

  &:hover {
    background-color: palette.$white;
    transform: scale(1.05);
  }
}
