@use "src/styles/layout";
@use "src/styles/typography";
@use "src/styles/palette";

.container {
  @include layout.container(xl);
  position: relative;
  width: 100%;
}

.hero {
  display: grid;
  width: 100%;
  padding: 10rem 1.5rem;
  position: relative;
  overflow: hidden;
  grid-template-columns: 1fr 1.5fr;
  grid-template-areas: 
    "content words"
    "share words";
  grid-row-gap: 1.5rem;
  align-content: center;
  align-items: center;

  justify-items: left;

  @media (max-width: layout.breakpoint(lg)) {
    padding: 5rem 0;
    grid-template-columns: 1fr;
    grid-template-areas:
      "content"
      "words"
      "share";
    justify-items: center;
    grid-row-gap: 4rem;
  }
}

.content {
  grid-area: content;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  color: var(--support-color);
  z-index: 1;
  align-self: flex-end;
  @media (max-width: layout.breakpoint(md)) {
    gap: 1rem;
  }
}

.image_and_wordcloud {
  position: relative;
  grid-area: words;
  display: grid;
  > * {
    grid-area: 1 / 1;
  }
}

.title {
  @extend %quiz_overline;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media (max-width: layout.breakpoint(md)) {
    gap: 0.5rem;
  }
}

.description {
  @extend .body__large;
  margin: 0;
}

.emphasis {
  @extend %quiz_header_2;
}

.image_wrapper {
  grid-area: image;
  display: grid;
  align-items: center;
  scale: 1.4;

  @media (max-width: layout.breakpoint(lg)) {
    scale: 1.2;
  }
}

.image {
  grid-area: 1 / 1;
  img {
    max-width: 100%;
    height: auto;
  }

  @media (max-width: layout.breakpoint(md)) {
    width: 100%;
    position: relative;
  }
}

.wordcloud {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.words_wrapper {
  grid-area: words;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  width: 100%;
  translate: 0 10%;
  justify-items: center;

  .word {
    // First row, first word
    &:nth-child(1) {
      grid-column: 1;
      grid-row: 1;
    }

    // Second row, first word (bottom)
    &:nth-child(2) {
      grid-column: 1;
      grid-row: 2;
      translate: 50% -20%;
    }

    // First row, second word (top)
    &:nth-child(3) {
      grid-column: 2;
      grid-row: 1;
    }

    // Second row, second word (bottom)
    &:nth-child(4) {
      grid-column: 2;
      grid-row: 2;
      translate: 50% -20%;
    }

    // First row, third word (top)
    &:nth-child(5) {
      grid-column: 3;
      grid-row: 1;
    }
  }

  @media (max-width: layout.breakpoint(md)) {
    .word {
      &:nth-child(3) {
        translate: 0 20%;
      }
      &:nth-child(2) {
        translate: 35% 0; 
      }
      &:nth-child(4) {
        translate: 65% 0; 
      }
    }
  }
}

.word {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.word_bubble {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--support-color);
  color: var(--profile-color);
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  padding: 1rem;

  width: clamp(3.5rem, calc(10vw), 8.625rem);
  height: clamp(3.5rem, calc(10vw), 8.625rem);
  
  span {
    @extend .header_5;
    text-align: center;
    font-size: clamp(0.875rem, -2rem + 4vw, 1.375rem);
  }

  @media (max-width: layout.breakpoint(md)) {
    width: auto;
    height: 100%;
    padding: 0.5rem;

    span {
      font-size: min(max(2vw, 0.875rem), calc(3.33vw));
    }
  }
}

.events {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.share {
  grid-area: share;
  display: flex;
  gap: 1rem;
  align-items: center;
  align-self: flex-start;
  color: var(--support-color);
}