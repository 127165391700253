@use "src/styles/layout";
@use "src/styles/typography";
@use "src/styles/palette";

$paragraphLineHeight: 1.5rem;

.card {
  text-decoration: none;
  display: grid;
  grid-template-columns: 18rem 1fr;
  gap: 1rem;

  @media (max-width: layout.breakpoint(md)) {
    grid-template-columns: 1fr;
  }
}

.image {
  width: 100%;
  img {
    transition: transform 0.3s;
  }
  &:hover {
    img {
      transform: scale(1.05);
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  > * {
    margin: 0;
  }

  .title {
    margin: 0;
    font-size: 1.375rem;
    line-height: 1.65rem;
  }

  .description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-height: $paragraphLineHeight;
    line-clamp: 3;
    align-self: stretch;
    overflow: hidden;

    // webkit-box and line-clamp are not yet supported in Firefox
    // so we need to set a max-height to make it work (without ellipsis unfortunately)
    max-height: calc($paragraphLineHeight * 3);
  }

  .button {
    margin-top: 0.5rem;
    background-color: var(--white);
    transition: transform 0.3s;

    &:hover {
      background-color: palette.$white;
      transform: scale(1.05);
    }
  }
}
