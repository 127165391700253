@use "src/styles/palette";

.quote {
  font-size: 1.125rem;
  line-height: 150%;
  font-weight: 700;
  border-left: 1px solid palette.brand(primary);
  padding-left: 1.25rem;
  margin-left: 1.25rem;
  position: relative;

  &:before {
    content: '“';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-50% ,-50%);
    color: palette.brand(primary);
    background: white;
    font-size: 1.375rem;
    height: 1.125rem;
    line-height: normal;
  }

  &_author {
    line-height: 200%;
    font-weight: 400;
  }
}