@use "src/styles/layout";
@use "src/styles/typography";

.break_word {
  @extend .break_word;
}

.hero {
  align-items: center;
  position: relative;

  .desktop_banner {
    max-width: 300px;

    @media (min-width: layout.breakpoint(md)) {
      max-width: 680px;
    }
  }

  .container_xl {
    padding: 3.75rem 1rem;

    @media (min-width: layout.breakpoint(lg)) {
      padding: 4.6875rem 1rem;
    }

    .sub_title {
      @extend .header_3;
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      max-width: 620px;

      .title {
        @extend .header_1;
      }
    }
  }
}

.theme {
  .container_xl {
    padding: 7.5rem 1rem 3.75rem;

    .title {
      padding-right: 2rem;
    }

    @media (min-width: layout.breakpoint(lg)) {
      padding: 4.6875rem 1rem;
    }
  }
}

[data-theme="750"] {
  .hero {
    background: var(--750-gradient-05);

    .container_xl {
      padding: 7rem 1rem 3.75rem;

      @media (min-width: layout.breakpoint(sm)) {
        padding: 11rem 1rem 3.75rem;
      }

      @media (min-width: layout.breakpoint(md)) {
        padding: 3.75rem 1rem;
      }
    }

    .title {
      padding-right: 2rem;
      text-transform: uppercase;
      > span {
        color: var(--black);
      }
    }
    .paragraph {
      color: var(--black);
    }
  }
}
